import moment from 'moment'

const isDev = process.env.NODE_ENV === 'development'
const isStag = window.location.host.includes('staging')

const UtilsDefault = {
  baseURL: isDev
    ? 'https://staging.updog-ai.com/api_v2/'
    : isStag
    ? 'https://staging.updog-ai.com/api_v2/'
    : 'https://app.updog-ai.com/api_v2/',
  adminBaseURL: isDev ? 'http://localhost:3000' : 'https://api.updog-ai.com',
  getInputDate: (info) => {
    if (info.useRange === true) {
      return `${moment(info.range[0].startDate).format('MMM Do')} - ${moment(
        info.range[0].endDate,
      ).format('MMM Do')}`
    } else {
      return moment(info.date).format('MMM Do')
    }
  },
  getPrevRoute: (path) => {
    let currLoc = path.split('/')
    console.log('utils.js : getPrevRoute() : Splice:', currLoc.join('/'))
    return currLoc.join('/')
  },
  getPreviousDay: () => {
    var d = new Date()
    var x = 1
    return d.setDate(d.getDate() - x)
  },
  colors: {
    orange: '#fb4c1c',
    lightblue: '#27bbd1',
  },
  between: (x, min, max) => {
    return x >= min && x <= max
  },
  isDevelopment: isDev,
  isStaging: isStag,
}

export default UtilsDefault
