import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import utils from './config/utils'

export const DataContext = createContext()

export const appRoutes = {
  DASHBOARD: 'dashboard',
  BUILDSHEET: 'buildsheet',
  TEMPCHECKS: 'tempchecks',
  TRENDS: 'trends',
  SALESWASTE: 'saleswaste',
  LOSTSALES: 'lostsales',
  ONTHEGRILL: 'onthegrill',
  MAINTENANCE: 'maintenance',
  CONCEPTS: 'concepts',
  CONCEPTDETAILS: 'conceptdetails',
  CONCEPTOWNERDETAILS: 'conceptownersdetails',
  CONCEPTLOCATIONDETAILS: 'conceptlocations',
  CONCEPTUSERDETAILS: 'conceptuserdetail',
  CONCEPTDEVICEDETAILS: 'conceptdevicedetails',
  CONCEPTNOTIFDETAILS: 'conceptnotificationgroupdetails',
  USERS: 'users',
  DEVICES: 'devices',
}

export const DataProvider = (props) => {
  const getCurrentToken = () => {
    const token = localStorage.getItem('token')
    return token
  }

  const [info, setInfo] = useState({
    date: utils.getPreviousDay(),
    useRange: false,
    range: [
      {
        startDate: utils.getPreviousDay(),
        endDate: utils.getPreviousDay(),
        key: 'selection',
      },
    ],
    useCalendar: false,
    active: 0,
    isLoading: false,
    refresh: false,
    isAuthenticated: false,
    token: getCurrentToken(),
    redirect: null,
  })

  const [route, setRoute] = useState(null)

  return (
    <DataContext.Provider
      value={{
        info: [info, setInfo],
        route: [route, setRoute],
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

DataProvider.propTypes = {
  children: PropTypes.node,
}
