import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GlobalDebug } from "./config/remove-consoles";
import utils from "./config/utils";
import "./scss/style.scss";
import "./css/tempchecks.css";
import "./css/details.css";
import "./css/schedules.css";
import "./css/custom.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const ROICalculator = React.lazy(() =>
  import("./views/pages/login/ROICalculator")
);

const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/login/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/login/ResetPassword")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const EndOfDayRemoval = React.lazy(() =>
  import("./views/main/EndOfDayRemoval")
);
const Waste = React.lazy(() => import("./views/main/Waste"));
const ViewingBuildSheet = React.lazy(() =>
  import("./views/main/ViewingBuildSheet")
);
const Maintenance = React.lazy(() => import("./views/main/MaintenancePDF"));
// Email App
const EmailApp = React.lazy(() => import("./views/apps/email/EmailApp"));

const App = () => {
  /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
  useEffect(() => {
    GlobalDebug(utils.isDevelopment === true || utils.isStaging === true);
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Login Page" element={<ROICalculator />} />
          {/* <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password Page"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/reset-password"
            name="Reset Password Page"
            element={<ResetPassword />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
          <Route path="/change-password" name="Change Password" element={<DefaultLayout />} />
          <Route
            path="/pdfs/EndofDayRemoval.pdf"
            name="End Of Day Removal"
            element={<EndOfDayRemoval />}
          />
          <Route path="/pdfs/Maintenance.pdf" name="Maintenance" element={<Maintenance />} />
          <Route
            path="/pdfs/ViewingBuildSheet.pdf"
            name="ViewingBuildSheet"
            element={<ViewingBuildSheet />}
          />
          <Route path="/pdfs/Waste.pdf" name="Waste" element={<Waste />} /> */}

          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
