import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const AdminContext = createContext()

export const type = {
  addConcept: 'addconcept',
}

export const AdminProvider = (props) => {
  const [isModalVisible, setModalIsVisible] = useState(false)

  return (
    <AdminContext.Provider value={[isModalVisible, setModalIsVisible]}>
      {props.children}
    </AdminContext.Provider>
  )
}

AdminProvider.propTypes = {
  children: PropTypes.node,
}
